













































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import MultiSelect, { MultiSelectOption } from 'client-website-ts-library/components/MultiSelect.vue';
import { ListingFilter } from 'client-website-ts-library/filters';
import { ListingCategory, ListingStatus } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';

@Component({
  components: {
    MultiSelect,
  },
})
export default class ListingSearch extends Vue {
  @Prop({ required: true })
  private readonly methodOfSale!: string;

  private mos: string = this.methodOfSale;

  private filter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    PageSize: 9,
  });

  private suburbs: MultiSelectOption[] = [];

  private selectedSuburbs: MultiSelectOption[] = [];

  private beds = 0;

  private baths = 0;

  mounted() {
    this.updateFilter();
  }

  @Watch('methodOfSale')
  updateMos() {
    this.mos = this.methodOfSale;
  }

  private updateSuburbsDebounce: number | undefined = undefined;

  updateSuburbs() {
    clearTimeout(this.updateSuburbsDebounce);

    this.updateSuburbsDebounce = window.setTimeout(() => {
      const tmpFilter = new ListingFilter(this.filter);

      tmpFilter.Suburbs = [];

      API.Listings.GetSuburbs(tmpFilter).then((suburbs) => {
        this.suburbs = suburbs.map((suburb) => ({ Value: suburb, Label: suburb }));
      });
    }, 2000);
  }

  @Watch('mos')
  @Watch('beds')
  @Watch('baths')
  updateFilter() {
    switch (this.mos) {
      case 'buy':
        this.filter.Categories = [
          ListingCategory.ResidentialSale,
          ListingCategory.ResidentialLand,
          ListingCategory.Rural,
        ];
        break;
      case 'rent':
        this.filter.Categories = [
          ListingCategory.ResidentialRental,
        ];
        break;
      default:
        this.filter.Categories = [];
        break;
    }

    this.updateSuburbs();

    this.filter.MinBedrooms = this.beds;
    this.filter.MinBathrooms = this.baths;

    this.filter.Suburbs = this.selectedSuburbs.map((s) => s.Value);
    this.filter.Page = 1;

    this.emitFilter();
  }

  suburbsUpdated(selectedSuburbs: MultiSelectOption[]) {
    this.selectedSuburbs = selectedSuburbs;

    this.updateFilter();
  }

  private emitDebounce: number | undefined = undefined;

  emitFilter() {
    this.$emit('filter_updated', this.filter);
  }
}
